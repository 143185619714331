import React, { useState,useEffect } from 'react'
import Loading from '../Components/loading';
import '../form.css'
import DonationForm from '../Components/DonationForm';
import RegistrationForm from '../Components/RegistrationForm';
import HeaderBar from '../Components/header';

const EventRegistration = ({guid, formType}) => {
  const [ft, setFT] = useState('');
  const [rid, setRid] = useState('');
  const [bannerCode, setbannerCode] = useState('');
  const [crmData, setCrmData] = useState({});
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);
  
  useEffect( () => {
    async function getdata(id){
      let url = process.env.REACT_APP_CRM_URL+`/eventreg/getdata`;
      let reqbody = {
       "key_id": ["guidView"],
       "key_operator": ["="],
       "keyValues": [id],
       "fields": 
        ["registrationIDView","eventNameView","chineseTitleView","englishTitleView","companyNameView",
        "postTitleView","fullNameView","registrationDeadlineView","pplPerTableView",
        "showTable1View","showTable2View","showTable3View","tablePrice1View","tablePrice2View",
        "tablePrice3View","remarksView","statusView","guidView","chineseFullNameView","postLabelLanguageView","englishFullNameView","showTable4View","MinAmountPerAttendeeView","MinCustomTablePriceView","eventguidView","contactIdView"],
       "viewId":"564295F4-12CD-EC11-AF1F-0211322A0315",
       "page_no": 1,
       "page_size": 3
     };
     const options = {
       method: "POST",
       headers: {
         'api-user': process.env.REACT_APP_API_USER,
         'api-key': process.env.REACT_APP_API_KEY,
         'content-type': 'application/json'
       },
       body:JSON.stringify(reqbody)
     }
      const data = await fetch(url,options).then(response => response.json())
     if(data.replyCode === 0){
        setCrmData(data.data.result[0]);
        setLoading(false);
     }
     console.log(data.data.result[0].eventNameView);
     
     let url2 = process.env.REACT_APP_CRM_URL+`/banner/getdata`;
      let reqbody2 = {
       "key_id": ["subject"],
       "key_operator": ["="],
       "keyValues": [data.data.result[0].eventNameView],
       "fields": 
        ["banner","subject","objectid"],       
       "page_no": 1,
       "page_size": 3
     };
     const options2 = {
       method: "POST",
       headers: {
         'api-user': process.env.REACT_APP_API_USER,
         'api-key': process.env.REACT_APP_API_KEY,
         'content-type': 'application/json'
       },
       body:JSON.stringify(reqbody2)
     }
      const data2 = await fetch(url2,options2).then(res => res.json())
      // const data3 = data2.json()   
      console.log()
      if(data2.replyCode === 0){
        setbannerCode(data2.data.result[0].banner);   
        console.log(data2.data.result[0].banner);     
     } 
    //  console.log(bannerCode);    
     
    };    

    window.matchMedia("(min-width: 768px)").addEventListener('change', e => setMatches( e.matches )); // check for browser resize
    getdata(guid).catch(err => console.log(err));
  },[]);

  return (
    <div className='form-container'>
      {
        matches ? 
        <div style={{width:"60%",justifyContent:'center',backgroundColor:'white'}}>
          <HeaderBar imagesource={bannerCode}/>
          { loading ? 
            <Loading loading={loading}/>:
            <div>
              {formType === 'donation'? <DonationForm recordId={guid} crmData={crmData} matches={matches}/> : <RegistrationForm recordid={guid} crmData={crmData} matches={matches}/>}
            </div>
          }
        </div> 
        : 
        <div style={{width:"100%",justifyContent:'center',backgroundColor:'white'}}> 
          <HeaderBar imagesource={bannerCode}/>
          { loading ? 
            <Loading loading={loading}/>:
            <div>
              {formType === 'donation'? <DonationForm recordId={guid} crmData={crmData} matches={matches}/> : <RegistrationForm recordId={guid} crmData={crmData} matches={matches}/>}
            </div>
          }
        </div>
      }

    </div>
  )
}

export default EventRegistration;