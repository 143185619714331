import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, BrowserRouter as Router, Routes, Route, NavLink,useSearchParams } from 'react-router-dom';
import EventRegistration from './Pages/EventRegistration';
import NotFound from './Components/NotFound';


function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [recordId, setRecordId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  function emptyCache(){
    if('caches' in window){
    caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });
  
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
    }
  }

  

  const getId = () => {
    const paras = new Proxy(new URLSearchParams(location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let Id = paras.recordid;
    if(!Id) return navigate('/'); 
    return Id;
  }

  return (
    <div className="App" style={{display: 'flex',flexDirection: 'column',alignContent: 'center',alignItems: 'center', backgroundColor:'#fafafa', minHeight:'100%'}}>
      <Routes>
          <Route path="/" element={<NotFound />}/>
          <Route path="/ad-reg-form" element={<EventRegistration guid={getId()} formType={'registration'}/>} />
          <Route path="/fd-reg-form" element={<EventRegistration guid={getId()} formType={'donation'}/>} />
      </Routes>
    </div>
  );
}

export default App;
