import React from 'react';
//import SuccessIcon from '../submit-successfully-128.png';

const EventEnded = () => {
  return (
    <div style={{display:'flex',flexDirection:'column',alignContent: 'center',alignItems: 'center',paddingTop:'4rem'}}>
      {/* <img src={SuccessIcon} alt="Submit Success" style={{width:'4rem',height:'4rem'}}/> */}
      <img src='/assets/submit-successfully-128.png' alt="Submit Success" style={{width:'4rem',height:'4rem'}}/>
      <div style={{fontSize:'3rem',textAlign:'center'}}>報名完畢，多謝！</div>
    </div>
  )
}

export default EventEnded;