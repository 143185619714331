import React, { useState,useEffect } from 'react';
//import React from "react";
import Image from 'react-bootstrap/Image';
// import Banner from '../images/banner.png';
import { useWindowWidthAndHeight } from "../helper/windowhelper";
import 'bootstrap/dist/css/bootstrap.min.css';
// import Logo from  '../images/DAB_logo-01.png';

const HeaderBar = ({imagesource}) => {
  useEffect( () => {
    // console.log(imagesource)
  },[imagesource]);
  const [width, height] = useWindowWidthAndHeight();
  return(
    <div style={{backgroundColor:'rgb(155, 201, 250)', display:'grid'}}>
      { width > 1000 ?
        <div>
          <div style={{display:'flex',flexDirection:'column'}}>
            {/* <div style={{fontSize:'3rem',textAlign:'left','width':'25%'}}> */}
              {/* <Image fluid src={Logo} />*/}
              {/*<img style={{height: 'auto',maxWidth: '100%'}}src='/assets/DAB_logo-01.png'/>*/}
            {/*</div>*/}
            {/* <Image fluid src={Banner} /> */}
            <img style={{height: 'auto',maxWidth: '100%'}}src={`data:image/jpeg;base64,${imagesource}`} />
          </div>
        </div>
        :
        <>
        <div>
          {/*<div style={{fontSize:'2rem','width':'30%'}}>*/}
            {/* <Image fluid src={Logo} /> */}
            {/*<img style={{height: 'auto',maxWidth: '100%'}}src='/assets/DAB_logo-01.png' />*/}
          {/*</div>*/}
          {/* <Image fluid src={Banner} /> */}
          <img style={{height: 'auto',maxWidth: '100%'}}src={`data:image/jpeg;base64,${imagesource}`} />
        </div>
        </>
        } 
    </div>
  )
}

export default HeaderBar;