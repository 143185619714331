import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
//import SuccessIcon from '../submit-successfully-128.png';
 

const SubmitSuccess = ({ crmData }) => { 

  const {guidView,statusView} = crmData;
  
  useEffect(() => {
    setTimeout(() => {
      if (statusView !== 100000002 && statusView !== 100000003) {
        window.location.reload();
      }
    }, 2000);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', paddingTop: '4rem' }}>
    {/* <img src={SuccessIcon} alt="Submit Success" style={{ width: '4rem', height: '4rem' }} /> */}
    <img src="/assets/submit-successfully-128.png" alt="Submit Success" style={{ width: '4rem', height: '4rem' }} />

    <div>
      {statusView === 100000002 ? (
        <div style={{ fontSize: '3rem', textAlign: 'center' }}>
          提交成功 - 已登記出席
          <br /><br />
          <QRCode value={guidView} />
        </div>
      ) : statusView === 100000003 ? (
        <div style={{ fontSize: '3rem', textAlign: 'center' }}>
          提交成功 - 已登記不出席
          <br /><br />
        </div>
      ) : (
        <div style={{ fontSize: '3rem', textAlign: 'center' }}>
          系統正在處理中...
          <br /><br />
        </div>
      )}
      <br />
    </div>
  </div>
);
};

export default SubmitSuccess;