import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import SubmitSuccess from './success';
import EventEnded from './eventEnded';
import moment from 'moment';
import Loading from '../Components/loading';


export default function DonationForm({crmData,matches}) {
  const AttendanceType ={
    'Attend' : 100000002,
    'Absent' : 100000003
  }
  const chineseTitleType = {
    '先生' : 100000000,
    '女士' : 100000001
  }
  const AuctionSponsoring ={false:0,true:1};

  const {postTitleView,companyNameView,fullNameView,chineseTitleView,registrationDeadlineView,pplPerTableView,showTable1View,showTable2View,showTable3View,tablePrice1View,tablePrice2View,tablePrice3View,eventNameView,statusView,guidView,showTable4View,englishTitleView,chineseFullNameView,englishFullNameView,postLabelLanguageView,remarksView,MinAmountPerAttendeeView,MinCustomTablePriceView,contactIdView} = crmData;
  const [originalStatus, setOriginalStatus] = useState(null);
  const [fulleNameWithTitle, setFullNameWithTitle] = useState('');
  const [acwapp_status, setAcwapp_status] = useState(null);
  const [auctionSelect, setAuctionSelect] = useState(false);
  const [attendCount, setAttendCount] = useState('');
  const [donationAmount, setDonationAmount] = useState('');  
  const [notAttendAndDonation, setNotAttendAndDonation] = useState('');
  const [primaryContact, setPrimaryContact] = useState('');
  const [primaryContactPhone, setPrimaryContactPhone] = useState('');
  const [bankAndCheque, setBankAndCheque] = useState('');
  const [receipt, setReceipt] = useState('');
  const [nameOfTable, setNameOfTable] = useState('');
  const [namePrint, setNamePrint] = useState('');
  const [DonationType, setDonationType] = useState('');
  const [eventfinished, seteventfinished] = useState('');
  const [loading, setLoading] = useState(false);
  

  
  const [table1Select, setTable1Select] = useState(false);
  const [table1Amount, setTable1Amount] = useState('');
  const [table2Select, setTable2Select] = useState(false);
  const [table2Amount, setTable2Amount] = useState('');
  const [table3Select, setTable3Select] = useState(false);
  const [table3Amount, setTable3Amount] = useState('');
  const [table4Select, setTable4Select] = useState(false);
  const [table4Amount, setTable4Amount] = useState('');
  const [table4ppl, setTable4ppl] = useState('');
  const [table4price, setTable4price] = useState('');
  const [tablePurchaseSelect, settablePurchaseSelect] = useState(false);
  const [SeatPurchaseSelect, setSeatPurchaseSelect] = useState(false);
  const [notAttendingSelect, setnotAttendingSelect] = useState(false);


  const { register, handleSubmit, unregister, formState: { errors } } = useForm({mode:'onSubmit'});
  const handletable1Select = (e) => {setTable1Select(e.target.checked)};
  const handletable1Amount = (e) => {setTable1Amount(e.target.value)};
  const handletable2Select = (e) => {setTable2Select(e.target.checked)};
  const handletable2Amount = (e) => {setTable2Amount(e.target.value)};
  const handletable3Select = (e) => {setTable3Select(e.target.checked)};
  const handletable3Amount = (e) => {setTable3Amount(e.target.value)};
  const handletable4Select = (e) => {setTable4Select(e.target.checked)};
  const handletable4Amount = (e) => {setTable4Amount(e.target.value)};
  const handletable4ppl = (e) => {setTable4ppl(e.target.checked)};
  const handletable4price = (e) => {setTable4price(e.target.value)};
  const handletablePurchaseSelect = (e) => {settablePurchaseSelect(e.target.checked)};
  const handleSeatPurchaseSelect = (e) => {setSeatPurchaseSelect(e.target.checked)};
  const handlenotAttendingSelect = (e) => {setnotAttendingSelect(e.target.checked)};
  
  

  // const getFullNameWithTitle = () => {
  //   const title = chineseTitleView === chineseTitleType.先生 ? "先生" : "女士"
  //   const fullNameTitle = fullNameView +' '+title
  //   setFullNameWithTitle(fullNameTitle); 
  //   console.log(fullNameTitle);
  // }
  
  // const getDonationData = async () => {
  //   let url = process.env.REACT_APP_CRM_URL+`/eventreg/${guidView}`; // pending
  //   const options = {
  //     method: "POST",
  //     headers: {
  //       'api-user': process.env.REACT_APP_API_USER,
  //       'api-key': process.env.REACT_APP_API_KEY,
  //       'content-type': 'application/json'
  //     }
  //   }
  //   const res = await fetch(url,options).then(response => response.json());
  //   if(res.replyCode === 0){
  //     setDonationRecord(res.data.result[0]);
  //   } else {
  //     alert(res.replyText);
  //   }
  // }

  const dynamicRenderCustomTable = (data,tablePurchaseSelect) => {
    const {showTable4View} = data;
    if (showTable4View === true) { // custom table is yes
      return(
        
      <div style={{display:'flex',flexDirection:'column',padding:'1rem'}}>
        <Col xs={12} md={12} style={{display:'flex',flexDirection:'row',height:'auto',flexWrap: 'nowrap'}}>
        <Col xs={1} md={12} style={{display:'flex',flexDirection:'row',height:'30px',flexWrap: 'wrap',width:'auto'}}>
        <div style={{textAlign:'center',height:'auto',verticalAlign: 'top',marginTop:'5px'}}>
                <Form.Check type="checkbox" style={{alignSelf:'center',textAlign:'left', height:'30px',paddingTop:'5px'}} disabled={!tablePurchaseSelect} name="attend1" label={<p></p>} checked={table4Select} {...register("table4Select",{ required: tablePurchaseSelect && (!table4Select && !table1Select && !table2Select && !table3Select) , pattern:{value: /^[0-9]/}, minLength: 0 })} onClick={(e) => {handletable4Select(e);setTable4Amount(''); setTable4price('');error_clear() }}/>
        </div>        
        </Col>
        <Col xs={11} md={12} style={{display:'flex',alignItems: 'left',flexDirection:'column',height:'auto',flexWrap: 'wrap'}}>
        <div style={{textAlign:'center',height:'auto',alignItems: 'center'}}>
          <Col xs={12} md={12} style={{display:'flex',alignItems: 'center',flexDirection:'row',height:'auto',flexWrap: 'wrap'}}>                        
                <Form.Label style={{textAlign:'left', height:'auto',margin:'0'}} htmlFor="name">贊助酒席</Form.Label>               
                <Form.Control style={{width:'7%', alignSelf:'center', height:'auto',minWidth:'43px',marginTop:'5px',textSizeAdjust:'auto'}} disabled={!tablePurchaseSelect || !table4Select} type="text"  value={table4Amount} placeholder={''} {...register("CustomTableAmount",{ required: tablePurchaseSelect && table4Select, pattern:{value: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*/}, minLength: 1 })} onChange={(input) => {handletable4Amount(input);seewhat('')}} />
                <Form.Label style={{textAlign:'left',height:'auto',margin:'0'}} htmlFor="name">檯 </Form.Label>
                <Form.Label style={{textAlign:'left',height:'auto',margin:'0'}} htmlFor="name">&#40;每檯港幣</Form.Label>
                <Form.Control style={{width:'11%', alignSelf:'center', height:'auto',minWidth:'90px',marginTop:'5px'}} disabled={!tablePurchaseSelect || !table4Select} type="text" value={table4price} placeholder={''} {...register("CustomTablePrice",{ required: tablePurchaseSelect && table4Select, pattern:{value: /^[1-9][0-9]*/}, minLength: 1, min: MinCustomTablePriceView })} onChange={(input) => handletable4price(input)} />
                <Form.Label style={{width:'11%', textAlign:'left',margin:'0'}} htmlFor="name">元&#41;</Form.Label>
          </Col>
        </div>
        <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>
          <Form.Label style={{textAlign:'left', minheight:'1px',height:'auto'}} htmlFor="name">最低贊助額每檯港幣{MinCustomTablePriceView}元</Form.Label>
        </div>
        <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>
          <Form.Label style={{textAlign:'left', minheight:'1px',height:'auto'}} htmlFor="name">No. of table &#40;Kindly sum up sponsorship starting from HK${MinCustomTablePriceView} per table&#41;</Form.Label>
        </div>
        <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>
          {(errors.CustomTableAmount || errors.CustomTablePrice) && <span className="text-danger">&nbsp;請輸入正確數目 Please enter correct amount</span>}
        </div>
        </Col>
        </Col>              
      </div >
      )
    }
  }

  const dynamicRenderTable1 = (data,tablePurchaseSelect) => {
    const {showTable1View} = data;
    if (showTable1View === true ) { // table 1 selected
      return(
        <div style={{display:'flex',flexDirection:'column',padding:'1rem'}}>
          <Col xs={12} md={12} style={{display:'flex',flexDirection:'row',height:'auto',flexWrap: 'nowrap'}}>
          <Col xs={1} md={12} style={{display:'flex',flexDirection:'row',height:'30px',flexWrap: 'wrap',width:'auto'}}>
            <div style={{textAlign:'center',height:'auto',verticalAlign: 'top',marginTop:'5px'}}>
              <Form.Check type="checkbox" style={{alignSelf:'center',textAlign:'left', height:'30px',paddingTop:'5px'}} disabled={!tablePurchaseSelect} name="attend2" label={<p></p>} checked={table1Select} {...register("table1Select",{ required: tablePurchaseSelect && (!table4Select && !table1Select && !table2Select && !table3Select) , pattern:{value: /^[0-9]/}, minLength: 0 })} onClick={(e) => {handletable1Select(e);setTable1Amount('');error_clear()}}/>
            </div>
          </Col>
          <Col xs={11} md={12} style={{display:'flex',alignItems: 'left',flexDirection:'column',height:'auto',flexWrap: 'wrap'}}>
          <div style={{textAlign:'center',height:'auto'}}>
            <Col xs={12} md={12} style={{display:'flex',alignItems: 'center',flexDirection:'row',height:'auto',flexWrap: 'wrap'}}>                
                <Form.Label style={{textAlign:'left', height:'auto',margin:'0'}} htmlFor="name">贊助酒席</Form.Label>
                <Form.Control style={{width:'7%', alignSelf:'center', height:'auto',minWidth:'43px',marginTop:'5px'}} disabled={!tablePurchaseSelect || !table1Select} type="text" value={table1Amount} placeholder={''} {...register("Table1Amount",{ required: tablePurchaseSelect && table1Select, pattern:{value: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*/}, minLength: 1 })} onChange={(input) => handletable1Amount(input)} />
                <Form.Label style={{textAlign:'left',height:'auto',margin:'0'}} htmlFor="name">檯 </Form.Label>
                <Form.Label style={{textAlign:'left',height:'auto',margin:'0'}} htmlFor="name">&#40;每檯港幣{tablePrice1View}元&#41;</Form.Label>
            </Col>              
          </div> 
          <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>             
            <Form.Label style={{textAlign:'left', minheight:'1px',height:'auto'}} htmlFor="name">No. of table &#40;Sponsorship start at HK${tablePrice1View} per table&#41;</Form.Label>
          </div>             
          <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>            
            {errors.Table1Amount && <span className="text-danger">&nbsp;請輸入正確數目 Please enter correct amount</span>}
          </div>
          </Col>
          </Col>            
        </div>
      )
    }
    else { // table 1 not selected
    <></>
    }
  }

  const dynamicRenderTable2 = (data,tablePurchaseSelect) => {
    const {showTable2View} = data;
    
    if (showTable2View === true ) { // table 2 selected
      return(
        <div style={{display:'flex',flexDirection:'column',padding:'1rem'}}>
          <Col xs={12} md={12} style={{display:'flex',flexDirection:'row',height:'auto',flexWrap: 'nowrap'}}>
            <Col xs={1} md={12} style={{display:'flex',flexDirection:'row',height:'30px',flexWrap: 'wrap',width:'auto'}}>
              <div style={{textAlign:'center',height:'auto',verticalAlign: 'top',marginTop:'5px'}}>
                <Form.Check type="checkbox" style={{alignSelf:'center',textAlign:'left', height:'30px',paddingTop:'5px'}} disabled={!tablePurchaseSelect} name="attend1" label={<p></p>} checked={table2Select} {...register("table2Select",{ required: tablePurchaseSelect && (!table4Select && !table1Select && !table2Select && !table3Select) , pattern:{value: /^[0-9]/}, minLength: 0 })} value="1" onClick={(e) => {handletable2Select(e);setTable2Amount('');error_clear()}}/>
              </div>
            </Col>
            <Col xs={11} md={12} style={{display:'flex',alignItems: 'left',flexDirection:'column',height:'auto',flexWrap: 'wrap'}}>
            <div style={{textAlign:'center',height:'auto'}}>
              <Col xs={12} md={12} style={{display:'flex',alignItems: 'center',flexDirection:'row',height:'auto',flexWrap: 'wrap'}}>                
                <Form.Label style={{textAlign:'left', height:'auto',margin:'0'}} htmlFor="name">贊助酒席</Form.Label>
                <Form.Control style={{width:'7%', alignSelf:'center', height:'auto',minWidth:'43px',marginTop:'5px'}} disabled={!tablePurchaseSelect || !table2Select} type="text" value={table2Amount} placeholder={''} {...register("Table2Amount",{ required: tablePurchaseSelect && table2Select, pattern:{value: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*/}, minLength: 1 })} onChange={(input) => handletable2Amount(input)} />
                <Form.Label style={{textAlign:'left',height:'auto',margin:'0'}} htmlFor="name">檯 </Form.Label>
                <Form.Label style={{textAlign:'left',height:'auto',margin:'0'}} htmlFor="name">&#40;每檯港幣{tablePrice2View}元&#41;</Form.Label>
              </Col>              
            </div>
            <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>               
              <Form.Label style={{textAlign:'left', minheight:'1px',height:'auto'}} htmlFor="name">No. of table &#40;Sponsorship start at HK${tablePrice2View} per table&#41;</Form.Label>
            </div>              
            <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>            
              {errors.Table2Amount && <span className="text-danger">&nbsp;請輸入正確數目 Please enter correct amount</span>}
            </div>
            </Col>
            </Col>
        </div>
      )
    }
    else { // table 2 not selected
    <></>
    }
  }

  const dynamicRenderTable3 = (data,tablePurchaseSelect) => {
    const {showTable3View} = data;

    
    if (showTable3View === true ) { // table 3 selected
      return(
        <div style={{display:'flex',flexDirection:'column',padding:'1rem'}}>
          <Col xs={12} md={12} style={{display:'flex',flexDirection:'row',height:'auto',flexWrap: 'nowrap'}}>
            <Col xs={1} md={12} style={{display:'flex',flexDirection:'row',height:'30px',flexWrap: 'wrap',width:'auto'}}>
              <div style={{textAlign:'center',height:'auto',verticalAlign: 'top',marginTop:'5px'}}>
                <Form.Check type="checkbox" style={{alignSelf:'center',textAlign:'left', height:'30px',paddingTop:'5px'}} disabled={!tablePurchaseSelect} name="attend1" label={<p></p>} checked={table3Select} {...register("table3Select",{ required: tablePurchaseSelect && (!table4Select && !table1Select && !table2Select && !table3Select) , pattern:{value: /^[0-9]/}, minLength: 0 })} value="1" onClick={(e) => {handletable3Select(e);setTable3Amount('');error_clear()}}/>
              </div>
            </Col>
            <Col xs={11} md={12} style={{display:'flex',alignItems: 'left',flexDirection:'column',height:'auto',flexWrap: 'wrap'}}>
            <div style={{textAlign:'center',height:'auto'}}>
              <Col xs={12} md={12} style={{display:'flex',alignItems: 'center',flexDirection:'row',height:'auto',flexWrap: 'wrap'}}>
                
                <Form.Label style={{textAlign:'left', height:'auto',margin:'0'}} htmlFor="name">贊助酒席</Form.Label>
                <Form.Control style={{width:'7%', alignSelf:'center', height:'auto',minWidth:'43px',marginTop:'5px'}} disabled={!tablePurchaseSelect || !table3Select} type="text" value={table3Amount} placeholder={''} {...register("Table3Amount",{ required: tablePurchaseSelect && table3Select, pattern:{value: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*/}, minLength: 1 })} onChange={(input) => handletable3Amount(input)} />
                <Form.Label style={{textAlign:'left',height:'auto',margin:'0'}} htmlFor="name">檯 </Form.Label>
                <Form.Label style={{textAlign:'left',height:'auto',margin:'0'}} htmlFor="name">&#40;每檯港幣{tablePrice3View}元&#41;</Form.Label>
              </Col>              
            </div>
            <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>              
              <Form.Label style={{textAlign:'left', minheight:'1px',height:'auto'}} htmlFor="name">No. of table &#40;Sponsorship start at HK${tablePrice3View} per table&#41;</Form.Label>
            </div>              
            <div style={{textAlign:'left',height:'auto',marginLeft:'0px'}}>            
              {errors.Table3Amount && <span className="text-danger">&nbsp;請輸入正確數目 Please enter correct amount</span>}
            </div>
            </Col>
            </Col>           
        </div>
      )
    }
    else { // table 3 not selected
    <></>
    }
  }
  useEffect(() => {
    setOriginalStatus(crmData.statusView);
    getDonationType();
    getFullNameWithTitle();
    getEventEnded();
    // getFullNameWithTitle();

    // console.log(`recordid: ${acwapp_eventregistraionid}`);
  },[])
  const onSubmit = async (data,e) => {
    setLoading(true); 
    e.preventDefault();
    const date_today = new Date();

    let url = process.env.REACT_APP_CRM_URL+`/actionmessage`;
    const base = {
      "key_id": "name",
      "name": date_today.getTime().toString(),
      "contact": contactIdView,
      "event": eventNameView,
      "eventRegistration": guidView,
      "donationType": getDonationType(),
      "registeredtoAttend": getRegisteredtoAttend(),
      "phoneNumber": data.phoneNumber,
      "auctionSponsoring": auctionSelect,
      "mainContactPerson": primaryContact,
      "mainContactPhone": data.phoneNumber2,
      "bankCheque": bankAndCheque,
      "nameOnReceipt": receipt,
      "nameOfDinnerTable": nameOfTable,
      "namePrinted": namePrint,
      "customTablePurchased": table4Amount,
      "customTablePrice": table4price,
      "table1Purchased": table1Amount,
      "table2Purchased": table2Amount,
      "table3Purchased": table3Amount,
      "numberOfAttendee": attendCount,
      "amountPerAttendee": donationAmount,
      "donationAmount": notAttendAndDonation



    }
    const options = {
      method: "POST",
      headers: {
        'api-user': process.env.REACT_APP_API_USER,
        'api-key': process.env.REACT_APP_API_KEY,
        'content-type': 'application/json'
      },
      body:JSON.stringify(base)
    }
    console.log(base); 
    const res = await fetch(url,options).then(response => response.json());
    if(res.replyCode === 0){
      setOriginalStatus(AttendanceType.Attend);
    } else {
      alert(res.replyText);
      setLoading(false);
      return;
    }

    let url2 = process.env.REACT_APP_CRM_URL+`/eventreg`;
    const base2 = {
      "key_id": "guid",
      "guid":crmData.guidView,
      "status":acwapp_status,
      "phoneNumber":data.phoneNumber,
      "auctionsSponsoring": auctionSelect
    }
    const options2 = {
      method: "PUT",
      headers: {
        'api-user': process.env.REACT_APP_API_USER,
        'api-key': process.env.REACT_APP_API_KEY,
        'content-type': 'application/json'
      },
      body:JSON.stringify(base2)
    }
    console.log(base2); 
    const res2 = await fetch(url2,options2).then(response => response.json());
    if(res2.replyCode === 0){
      setOriginalStatus(AttendanceType.Attend);
    } else {
      alert(res.replyText);
    }
    setLoading(false);
  };
  const handleAttend = (selected) => { selected ? setAcwapp_status(AttendanceType.Attend) : setAcwapp_status(AttendanceType.Absent);}
  const handleAttendCount         = (e) =>  {setAttendCount(e.target.value);}
  const handleDonationAmount      = (e) =>  {setDonationAmount(e.target.value);}
  const handleNotAttendAndDonate  = (e) =>  {setNotAttendAndDonation(e.target.value);}
  const handlePrimaryContact      = (e) =>  {setPrimaryContact(e.target.value);}
  const handlePrimaryContactPhone = (e) =>  {setPrimaryContactPhone(e.target.value);}
  const handleBankAndCheque       = (e) =>  {setBankAndCheque(e.target.value);}
  const handleReceipt             = (e) =>  {setReceipt(e.target.value);}
  const handleNameOfTable         = (e) =>  {setNameOfTable(e.target.value);}
  const handleNamePrint           = (e) =>  {setNamePrint(e.target.value);}

  const onErrors = errors => {console.error(errors)};
  const handleAuctionSelect = data => {
    setAuctionSelect(!auctionSelect);
    console.log(`auctionSelect: ${auctionSelect}`); 
  }
  //check status first.

  const seewhat = (input) => {
   console.log(input);
  }

  const getDonationType = () => {
    if(!tablePurchaseSelect && !SeatPurchaseSelect && !notAttendingSelect)
    {
      setDonationType('');
      return '';
    }
    else if (tablePurchaseSelect)
    {
      setDonationType('100000000');
      return '100000000';
    }
    else if (SeatPurchaseSelect)
    {
      setDonationType('100000001');
      return '100000001';
    }
    else if (notAttendingSelect && notAttendAndDonation != '' && notAttendAndDonation != '0')
    {
      setDonationType('100000002');
      return '100000002';
    }

    setDonationType('');
    return '';



  }

  const getRegisteredtoAttend = () => { //make a state for RegisteredtoAttend
    if(tablePurchaseSelect || SeatPurchaseSelect)
    {
      return true;
    }
    else 
    {
      return false;
    }

  }


  const englishTitleType = {
    'Mr.' : 100000000,
    'Ms.' : 100000001
  }

  const getFullNameWithTitle = () => {
    const chi_title = chineseTitleView === chineseTitleType.先生 ? "先生" : chineseTitleView === chineseTitleType.女士 ? "女士" : ""
    const eng_title = englishTitleView === englishTitleType['Mr.'] ? "Mr. " : englishTitleView === englishTitleType['Ms.'] ? "Ms. " : ""
    const chi_fullNameTitle = chineseFullNameView +''+ chi_title
    const eng_fullNameTitle = eng_title +''+ englishFullNameView
    if(postLabelLanguageView)
    {
      setFullNameWithTitle(eng_fullNameTitle); 
    } else 
    {
      setFullNameWithTitle(chi_fullNameTitle); 
    }
  }
  
  const getEventEnded = () => {
    const date_today = new Date()
    const regdeadline = new Date(Date.parse(registrationDeadlineView))
    regdeadline.setHours(23, 59, 59)
    


    if(date_today >= regdeadline)
   
    {
      seteventfinished('1');
    } else {
      seteventfinished('0');
    } 
    


  }

  

  const error_clear = () => {
    unregister("CustomTableAmount");
    unregister("CustomTablePrice");
    unregister("Table1Amount");
    unregister("Table2Amount");
    unregister("Table3Amount");
    unregister("attendCount");
    unregister("donationAmount");
    unregister("NotAttendAndDonation");

  }

  return (

    <>
    {
      eventfinished === '1' ? <EventEnded /> : loading ? 
      <Loading loading={loading}/> :
      originalStatus === AttendanceType.Attend || originalStatus ===  AttendanceType.Absent ?
      <SubmitSuccess crmData={crmData} /> :
      <>
      <div style={{display:'flex',flexDirection:'column',padding:'2rem'}}>
        <h4 style={{textAlign:'center',color:'rgb(0, 87, 176)'}}>贊助表</h4>
        <h4 style={{textAlign:'center',color:'rgb(0, 87, 176)'}}>Memorandum of Donation</h4>
        <h4 style={{textAlign:'center',color:'rgb(0, 87, 176)'}}>{eventNameView}</h4>
        <h6 style={{textAlign:'center'}}>(請於{moment(registrationDeadlineView).add(8, 'hours').format("YYYY[年]M[月]D[日]")}或前回覆)</h6>
        <h6 style={{textAlign:'center'}}>(Kindly reply by {moment(registrationDeadlineView).add(8, 'hours').format("YYYY-M-D")})</h6>      
      </div >
      <Container fluid>
        <Form onSubmit={handleSubmit(onSubmit,onErrors)}>
        <Row >
          <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
            <Form.Label style={{width:'30%',textAlign:'left'}} htmlFor="name">姓名:<br/>Name:</Form.Label>
            <Form.Control style={{width:'80%'}} type="text" disabled={true} placeholder={fulleNameWithTitle} {...register("fullNameView")} /> 
          </Col>
          <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
            <Form.Label style={{width:'30%',textAlign:'left'}} htmlFor="name">公司名稱:<br/>Company:</Form.Label>
            <Form.Control style={{width:'80%'}} type="text" disabled={true} placeholder={companyNameView} {...register("companyNameView")} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
            <Form.Label style={{width:'30%',textAlign:'left'}} htmlFor="posttitle">職銜:<br/>Post Title:</Form.Label>
            <Form.Control style={{width:'80%'}} type="text" disabled={true} placeholder={postTitleView} {...register("postTitleView")} />
          </Col>
          <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
            <Form.Label style={{width:'30%',textAlign:'left'}} htmlFor="phone">電話號碼:<br/>Phone No.:</Form.Label>
            <Form.Control style={{width:'80%'}} type="tel" placeholder={""} {...register("phoneNumber",{ required: true, pattern:{value: /^(0|[1-9]\d*)(\.\d+)?$/}, minLength: 8, maxLength: 11 })} />
          </Col>
          
        </Row>
        <Row>
           <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
          </Col>
         <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'right'}}>
         {errors.phoneNumber && <span className="text-danger">請輸入正確的手機號碼 Please fill in correct phone no.</span>}
         </Col>
        </Row>
        {/* Attend */}
        {/*<Col xs={12} md={12} style={{display:'flex',paddingLeft:'1rem',paddingRight:'1rem',marginBottom:'1px',backgroundColor:'rgb(246, 245, 241)'}}>
          <div style={{display:'flex',flexDirection:'column',padding:'1rem',paddingBottom:'2rem'}}>
            <Form.Label style={{display:'flex',padding:'1rem 1rem 1rem 0',fontSize:'1.2rem',fontWeight:'bold',color:'rgb(47, 118, 183)'}}>出席</Form.Label>
            <Form.Check type="radio" name="attend" label={`本人將會出席${eventNameView}`} value={AttendanceType.Attend} onClick={() => handleAttend(true)}/>
            <br/>
            <Form.Check type="radio" name="attend" label={`本人無暇出席${eventNameView}`} value={AttendanceType.Absent} onClick={() => handleAttend(false)}/>
          </div>
    </Col>*/}
        {/* Attend */}
        {/* Amount */}
        {/*<Col xs={12} md={12} style={{display:'flex',paddingLeft:'1rem',paddingRight:'1rem',marginBottom:'1px',backgroundColor:'rgb(246, 245, 241)'}}>
          <div style={{display:'flex',flexDirection:'column',padding:'1rem', width:'100%'}}>
            <Form.Label style={{display:'flex',padding:'1rem 1rem 1rem 0',fontSize:'1.2rem',fontWeight:'bold',color:'rgb(47, 118, 183)'}}>人數及贊助金額</Form.Label>
              <Row>
              <Col xs={12} md={12} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                <Form.Label style={{width:'30%', textAlign:'left'}} htmlFor="name">出席人數: </Form.Label>
                <Form.Control style={{width:'80%'}} type="text" placeholder={attendCount} onChange={(input) => handleAttendCount(input)} />
              </Col>
              </Row>
              <Row>
              <Col xs={12} md={12} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                <Form.Label style={{width:'30%', textAlign:'left'}} htmlFor="name">每位金額: </Form.Label>
                <Form.Control style={{width:'80%'}} type="text" placeholder={donationAmount} onChange={(input) => handleDonationAmount(input)} />
              </Col>
              </Row>
            <Row>
              <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                <Form.Label style={{width:'30%', textAlign:'left'}} htmlFor="name">無暇出席并願意贊助金額: </Form.Label>
                <Form.Control style={{width:'80%'}} type="text" placeholder={notAttendAndDonation} onChange={(input) => handleNotAttendAndDonate(input)} />
              </Col>
            </Row>
          </div>
        </Col>*/}
        {/* Amount */}
        {/* Reservation */}
        <Col xs={12} md={12} style={{display:'flex',paddingLeft:'0rem',paddingRight:'1rem',marginBottom:'1px',backgroundColor:'rgb(246, 245, 241)'}}>
          <div style={{display:'flex',flexDirection:'column',padding:'1rem', width:'100%'}}>
              <div style={{display:'flex',paddingTop:'1rem',paddingLeft:'1rem',flexDirection:'column',textAlign:'left'}}>
                {(errors.tablePurchaseSelect && errors.SeatPurchaseSelect && errors.notAttendingSelect) && <span className="text-danger">請從酒席認購/ 酒位認購/ 無暇出席中選擇一項。Please choose one from Table Sponsorship/ Seat Sponsorship/ Not Attending below.</span>}
             </div>
             <div style={{display:'flex',flexDirection:'row',padding:'1rem', width:'100%', paddingLeft:'16px', paddingBottom:'0px'}}>
              <Form.Check type="checkbox" style={{alignSelf:'center',textAlign:'left', height:'30px'}} name="attend1" label={<p></p>} value="1" checked={tablePurchaseSelect} {...register("tablePurchaseSelect",{ required: !tablePurchaseSelect && !SeatPurchaseSelect && !notAttendingSelect})} onClick={(e) => {handletablePurchaseSelect(e);error_clear();setAcwapp_status(AttendanceType.Attend);setSeatPurchaseSelect(false);setnotAttendingSelect(false); setTable4Amount(''); setTable4price('');setTable3Amount('');setTable2Amount('');setTable1Amount(''); setTable4Select(false); setTable1Select(false); setTable2Select(false);setTable3Select(false); setAttendCount('');setDonationAmount('');setNotAttendAndDonation('')}}/>
              <Form.Label style={{display:'flex',padding:'1rem',fontSize:'1.2rem',fontWeight:'bold',color:'rgb(47, 118, 183)'}}>酒席認購 Table Sponsorship</Form.Label>
             </div>
             
            <h6 style={{textAlign:'left'}}>&nbsp;&nbsp;&nbsp;&nbsp;*每檯{pplPerTableView}人</h6>
            <h6 style={{textAlign:'left'}}>&nbsp;&nbsp;&nbsp;&nbsp;Table for {pplPerTableView}</h6>
            <div style={{display:'flex',paddingTop:'1rem',paddingLeft:'1rem',flexDirection:'column',textAlign:'left'}}>
                {(errors.table4Select || errors.table3Select || errors.table2Select || errors.table1Select) && <span className="text-danger">請選擇最少一檯酒席 Please choose at least one table</span>}
             </div>
            <div style={{display:'flex',paddingTop:'1rem',paddingLeft:'1rem',paddingRight:'1rem', flexDirection:'column',textAlign:'left'}}>
            {
              dynamicRenderCustomTable(crmData,tablePurchaseSelect)
            }
            {
              dynamicRenderTable1(crmData,tablePurchaseSelect)
            }
            {
              dynamicRenderTable2(crmData,tablePurchaseSelect)
            }
            {
              dynamicRenderTable3(crmData,tablePurchaseSelect)
            }
            </div>
          </div>
        </Col>
        {/* Seat */}
        {/* Purchase */}
        <Col xs={12} md={12} style={{display:'flex',paddingLeft:'1rem',paddingRight:'1rem',marginBottom:'1px',backgroundColor:'rgb(246, 245, 241)'}}>
          <div style={{display:'flex',flexDirection:'column',padding:'1rem', width:'100%'}}>
             <div style={{display:'flex',flexDirection:'row',padding:'0rem', width:'100%', paddingLeft:'0px'}}>
              <Form.Check type="checkbox" style={{alignSelf:'center',textAlign:'left', height:'30px'}} name="attend1" label={<p></p>} value="1" checked={SeatPurchaseSelect} {...register("SeatPurchaseSelect",{ required: !tablePurchaseSelect && !SeatPurchaseSelect && !notAttendingSelect})} onClick={(e) => {handleSeatPurchaseSelect(e);error_clear();setAcwapp_status(AttendanceType.Attend);settablePurchaseSelect(false);setnotAttendingSelect(false);setAttendCount('');setDonationAmount('');setTable4Amount(''); setTable4price('');setTable3Amount('');setTable2Amount('');setTable1Amount('');setTable4Select(false);setTable1Select(false);setTable2Select(false);setTable3Select(false);setNotAttendAndDonation('')}}/>
              <Form.Label style={{display:'flex',padding:'1rem 1rem 1rem 0',fontSize:'1.2rem',fontWeight:'bold',color:'rgb(47, 118, 183)'}}>席位認購 Seat Sponsorship</Form.Label>
            </div>            
              <Row>
              <Col xs={12} md={6} style={{display:'flex',paddingBottom:'0rem',alignItems: 'center',paddingLeft:'12px'}}>
                <Form.Label style={{width:'71%',textAlign:'left'}} htmlFor="name">出席人數:<br/>Number of guests:&nbsp;&nbsp;</Form.Label>
                <Form.Control style={{width:'70%', textAlign:'left'}} type="text" label={`贊助酒席 (每檯${pplPerTableView}人, 港幣${tablePrice1View}元)`} disabled={!SeatPurchaseSelect} placeholder={attendCount} value={attendCount} {...register("attendCount",{ required: SeatPurchaseSelect, pattern:{value: /^[1-9][0-9]*/}, minLength: 1})} onChange={(input) => handleAttendCount(input)} />
              </Col>
              </Row>

              <Row>
              <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center',paddingLeft:'12px'}}>
              {errors.attendCount && <span className="text-danger">請輸入正確數目 Please enter correct amount</span>}
              </Col>
              </Row>
              
              <Row>
              <Col xs={12} md={6} style={{display:'flex',paddingBottom:'0rem',alignItems: 'center', paddingLeft:'12px'}}>
                <Form.Label style={{width:'60%',textAlign:'left'}} htmlFor="name">每位金額:<br/>Amount per pax:</Form.Label>
                <Form.Label style={{width:'5%',textAlign:'center'}} htmlFor="name">$</Form.Label>
                <Form.Control style={{width:'70%', textAlign:'left'}} type="text" disabled={!SeatPurchaseSelect} placeholder={donationAmount} value={donationAmount} {...register("donationAmount",{ required: SeatPurchaseSelect, pattern:{value: /^[1-9][0-9]*/}, minLength: 1, min: MinAmountPerAttendeeView })} onChange={(input) => handleDonationAmount(input)} />
              </Col>
              </Row>

              <Row>
              <Col xs={12} md={12} style={{display:'flex',paddingBottom:'1rem',alignItems: 'left',paddingLeft:'12px'}}>
                <Form.Label style={{textAlign:'left', height:'1px', paddingLeft:'0px'}} htmlFor="name">最低贊助額每位港幣{MinAmountPerAttendeeView}元 &#40;Sponsorship start at HK${MinAmountPerAttendeeView} per pax&#41;</Form.Label>
              </Col>
              </Row>

              <Row>
              <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center',paddingLeft:'12px'}}>
              {errors.donationAmount && <span className="text-danger">請輸入正確數目 Please enter correct amount</span>}
              </Col>
              </Row>
          </div>
        </Col>
        {/* Not */}
        {/* Attending */}
        <Col xs={12} md={12} style={{display:'flex',paddingLeft:'1rem',paddingRight:'1rem',marginBottom:'1px',backgroundColor:'rgb(246, 245, 241)'}}>
          <div style={{display:'flex',flexDirection:'column',padding:'1rem', width:'100%'}}>
            <div style={{display:'flex',flexDirection:'row',padding:'0rem', width:'100%', paddingLeft:'0px'}}>
              <Form.Check type="checkbox" style={{alignSelf:'center',textAlign:'left', height:'30px'}} name="attend1" label={<p></p>} value="1" checked={notAttendingSelect} {...register("notAttendingSelect",{ required: !tablePurchaseSelect && !SeatPurchaseSelect && !notAttendingSelect})} onClick={(e) => {handlenotAttendingSelect(e);error_clear();setAcwapp_status(AttendanceType.Absent);settablePurchaseSelect(false);setSeatPurchaseSelect(false);setAttendCount('');setDonationAmount('');setTable4Amount(''); setTable4price('');setTable3Amount('');setTable2Amount('');setTable1Amount('');setTable4Select(false);setTable1Select(false);setTable2Select(false);setTable3Select(false);setNotAttendAndDonation('')}}/>
              <Form.Label style={{display:'flex',padding:'1rem 1rem 1rem 0',fontSize:'1.2rem',fontWeight:'bold',color:'rgb(47, 118, 183)'}}>無暇出席 Not Attending</Form.Label>
            </div>            
            <Row>
              <Col xs={12} md={6} style={{display:'flex',paddingBottom:'0rem',alignItems: 'center'}}>
                <Form.Label style={{width:'60%',textAlign:'left'}} htmlFor="name">願意贊助金額:<br/>Donation amount:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Form.Label>
                <Form.Label style={{width:'5%',textAlign:'center'}} htmlFor="name">$</Form.Label>
                <Form.Control style={{width:'70%'}} type="text"  disabled={!notAttendingSelect} placeholder={notAttendAndDonation} value={notAttendAndDonation} {...register("NotAttendAndDonation",{ required: notAttendingSelect, pattern:{value: /^[1-9][0-9]*/}, minLength: 1 })} onChange={(input) => handleNotAttendAndDonate(input)} />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center',paddingLeft:'12px'}}>
                {errors.NotAttendAndDonation && <span className="text-danger">請輸入正確數目 Please enter correct amount</span>}
              </Col>
            </Row>
          </div>
        </Col>

        {/* Reservation */}
        {/* Auction */}
        <Col xs={12} md={12} style={{display:'flex',paddingLeft:'1rem',paddingRight:'1rem',marginBottom:'1px',backgroundColor:'rgb(246, 245, 241)'}}>
          <div style={{display:'flex',flexDirection:'column',padding:'1rem', width:'100%'}}>
            <div style={{display:'flex',flexDirection:'row',padding:'0rem', width:'100%', paddingLeft:'0px'}}>
              <Form.Check 
                style={{width:'0.5rem',textAlign:'left',alignSelf:'center',paddingLeft:'2px',marginRight:'1rem'}} 
                type="checkbox" 
                name="auction" 
                value={auctionSelect} 
                onClick={(select) => {handleAuctionSelect(select);}}/>
              <Form.Label style={{display:'flex',padding:'1rem 1rem 1rem 0',fontSize:'1.2rem',fontWeight:'bold',color:'rgb(47, 118, 183)'}}>拍賣/節目贊助 Auction/Program for Sponsorship</Form.Label>
            </div>             
             <div style={{display:'flex', flexDirection:'column', textAlign:'left', paddingLeft:'3px'}}> 
              
                <label>本人有意參與當晚拍賣/節目贊助&#40;詳情秘書處將另行聯絡&#41;</label>
              
              
                <label>I would like to take part in the event's auction/ program sponsoring. &#40;DAB's secretariat will contact you for details in due course.&#41;</label>
              
             </div>
          </div>
        </Col>
        {/* Auction */}
        {/* Donation Detail */}
        <Col xs={12} md={12} style={{display:'flex',paddingLeft:'1rem',paddingRight:'1rem',marginBottom:'1px',backgroundColor:'rgb(246, 245, 241)'}}>
          <div style={{display:'flex',flexDirection:'column',padding:'1rem', width:'100%'}}>
            <Form.Label style={{display:'flex',padding:'1rem 1rem 1rem 0',fontSize:'1.2rem',fontWeight:'bold',color:'rgb(47, 118, 183)'}}>贊助資料 Sponsoring Particulars</Form.Label>
              <Row>
                <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                  <Form.Label style={{width:'66%',textAlign:'left'}} >主要聯絡人姓名:<br/>Contact person:</Form.Label>
                  <Form.Control style={{width:'70%'}} type="text"  onChange={(input) => handlePrimaryContact(input)} />
                </Col>
                <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                  <Form.Label style={{width:'66%',textAlign:'left'}} >主要聯絡人電話:<br/>Contact phone no.:</Form.Label>
                  <Form.Control style={{width:'70%'}} type="text"  onChange={(input) => handlePrimaryContactPhone(input)} {...register("phoneNumber2",{ required: false, pattern:{value: /^(0|[1-9]\d*)(\.\d+)?$/}, minLength: 8, maxLength: 11 })}/>
                </Col>
                
              </Row>
              <Row>
                <Col xs={12} md={6} style={{display:'flex',paddingBottom:'0rem',alignItems: 'center'}}>
                </Col>
                <Col xs={12} md={6} style={{display:'flex',paddingBottom:'0rem',alignItems: 'right'}}>
                {errors.phoneNumber2 && <span className="text-danger">請輸入正確的手機號碼 Please enter correct phone no.</span>}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                  <Form.Label style={{width:'66%',textAlign:'left'}} >銀行與支票號碼:<br/>Bank and cheque no:</Form.Label>
                  <Form.Control style={{width:'70%'}} type="text"  onChange={(input) => handleBankAndCheque(input)} />
                </Col>
                <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                  <Form.Label style={{width:'66%',textAlign:'left'}} >收據抬頭:<br/>Name to be shown on receipt:</Form.Label>
                  <Form.Control style={{width:'70%'}} type="text"  onChange={(input) => handleReceipt(input)} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                  <Form.Label style={{width:'66%',textAlign:'left'}} >酒席冠名:<br/>Name to be shown on<br/>the sponsored table:</Form.Label>
                  <Form.Control style={{width:'70%'}} type="text"  onChange={(input) => handleNameOfTable(input)} />
                </Col>
                <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
                  <div style={{display:'flex',flexDirection:'column',width:'66%'}}>
                    <Form.Label style={{textAlign:'left'}} > 鳴謝名稱(刊登於晚會場刊):</Form.Label>
                    <Form.Label style={{textAlign:'left'}} >Name to be shown in the pamphlet as acknowledgement:</Form.Label>
                  </div>
                  <Form.Control style={{width:'70%'}} type="text" onChange={(input) => handleNamePrint(input)} />
                </Col>
              </Row>
          </div>
        </Col>
        <Col>
        <div style={{display:'flex',paddingTop:'1rem',paddingLeft:'1rem',flexDirection:'column',textAlign:'left'}}>
           <Form.Label style={{textAlign:'left', fontWeight: "bold"}} >{remarksView}</Form.Label>
          </div>
        </Col>
        {/* Donation Detail */}
        
        <Row style={{paddingBottom:'1.5rem',paddingTop:'1.5rem'}}>
        {
          !matches?
          <Col style={{textAlign:'center',paddingBottom:'1rem'}}>
          <Button type="submit" value="提交" style={{width:'200px',backgroundColor:'rgb(0, 97, 176)'}}>提交 Submit</Button>
        </Col>          
          :
          <Col style={{textAlign:'left',paddingBottom:'1rem',paddingLeft:'2rem',height: '3rem'}}>
          <Button type="submit" value="提交" style={{width:'200px',fontSize:'1.5rem',backgroundColor:'rgb(0, 97, 176)'}}>提交 Submit</Button>
        </Col>
        }
      </Row>
        </Form>
      </Container>
      </>
    }
    
    </>
  
  )
}
