import React from "react";
import { Oval } from  'react-loader-spinner'

const Loading = (props) => {
  return (
    <>
    {props.loading ? 
        (<Oval wrapperStyle={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}
          color="#00BFFF" 
          height={200} 
          width={200} 
          ariaLabel='loading' />) : 
        (<></>)}
    </>
  )
}

export default Loading;