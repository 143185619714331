import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import SubmitSuccess from './success';
import EventEnded from './eventEnded';
import moment from 'moment';

export default function RegistrationForm({crmData,matches}) {
  //check status first.
  const {postTitleView,companyNameView,fullNameView,chineseTitleView,englishTitleView,registrationDeadlineView,pplPerTableView,showTable1View,showTable2View,showTable3View,tablePrice1View,tablePrice2View,tablePrice3View,remarksView,eventNameView,statusView,guidView,chineseFullNameView,englishFullNameView,postLabelLanguageView} = crmData;
  const [originalStatus, setOriginalStatus] = useState(null);
  const [fulleNameWithTitle, setFullNameWithTitle] = useState('');
  const [checkformlabel1, setcheckformlabel1] = useState('');
  const [checkformlabel2, setcheckformlabel2] = useState('');
  const [acwapp_status, setAcwapp_status] = useState(null);
  const [acwapp_phonenumber, setAcwapp_phonenumber] = useState(null);
  const [donationRecord, setDonationRecord] = useState({});
  const [todaydate, settodaydate] = useState('');
  const [regdeadline, setregdeadline] = useState('');
  const [eventfinished, seteventfinished] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm({mode:'onSubmit'});
  const AttendanceType ={
    'Attend' : 100000002,
    'Absent' : 100000003
  }
  const chineseTitleType = {
    '先生' : 100000000,
    '女士' : 100000001
  }

  const englishTitleType = {
    'Mr.' : 100000000,
    'Ms.' : 100000001
  }
  const handleAttend = (selected) => { 
    selected ? setAcwapp_status(AttendanceType.Attend) : setAcwapp_status(AttendanceType.Absent);
  }
  const onSubmit = async (data,e) => {
    e.preventDefault();
    let url = process.env.REACT_APP_CRM_URL+`/eventreg`;
    const base = {
      "key_id": "guid",
      "guid":crmData.guidView,
      "status":acwapp_status,
      "phoneNumber":data.phoneNumber
    }
    const options = {
      method: "PUT",
      headers: {
        'api-user': process.env.REACT_APP_API_USER,
        'api-key': process.env.REACT_APP_API_KEY,
        'content-type': 'application/json'
      },
      body:JSON.stringify(base)
    }
    console.log(base); 
    const res = await fetch(url,options).then(response => response.json());
    if(res.replyCode === 0){
      setOriginalStatus(AttendanceType.Attend);
    } else {
      alert(res.replyText);
    }
  };
  const getFullNameWithTitle = () => {
    const chi_title = chineseTitleView === chineseTitleType.先生 ? "先生" : chineseTitleView === chineseTitleType.女士 ? "女士" : ""
    const eng_title = englishTitleView === englishTitleType['Mr.'] ? "Mr. " : englishTitleView === englishTitleType['Ms.'] ? "Ms. " : ""
    const chi_fullNameTitle = chineseFullNameView +''+ chi_title
    const eng_fullNameTitle = eng_title +''+ englishFullNameView
    if(postLabelLanguageView)
    {
      setFullNameWithTitle(eng_fullNameTitle); 
    } else 
    {
      setFullNameWithTitle(chi_fullNameTitle); 
    }
  }

  const getTodayDate = () => {
    const date_today = new Date()
    settodaydate(date_today); 
  }

  const getEventEnded = () => {
    const date_today = new Date()
    const regdeadline = new Date(Date.parse(registrationDeadlineView))
    regdeadline.setHours(23, 59, 59)

    if(date_today > regdeadline)
   
    {
      seteventfinished('1');
    } else {
      seteventfinished('0');
    } 
    setregdeadline(regdeadline);

  }



  const getcheckformlabel1 = () => {
    const label1_1 = `本人將會出席${eventNameView}。&lt;br&gt; I will participate in ${eventNameView}.`
    const label1_2 = `I will participate in ${eventNameView}.`
    const fulllabel1 = label1_1
    setcheckformlabel1(fulllabel1);
  }

  const onErrors = errors => {console.error(errors)};
  useEffect(() => {
    setOriginalStatus(crmData.statusView);
    getFullNameWithTitle();
    getcheckformlabel1();
    getTodayDate();
    getEventEnded();
  },[])
  return (
    <>
    {
    eventfinished === '1' ? <EventEnded /> :
    originalStatus === AttendanceType.Attend || originalStatus ===  AttendanceType.Absent ?
    <SubmitSuccess crmData={crmData} /> :
    <>
    <div style={{display:'flex',flexDirection:'column',padding:'2rem'}}>
      <h4 style={{textAlign:'center',color:'rgb(0, 87, 176)'}}>{eventNameView}</h4>
      <h6 style={{textAlign:'center'}}>(請於{moment(registrationDeadlineView).add(8, 'hours').format("YYYY[年]M[月]D[日]")}或前回覆)</h6>
      <h6 style={{textAlign:'center'}}>(Please reply before {moment(registrationDeadlineView).add(8, 'hours').format("YYYY[-]M[-]D")}.)</h6>
    </div >
    <Container fluid>
      <Form onSubmit={handleSubmit(onSubmit,onErrors)}>
        <Row >
          <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
            <Form.Label style={{width:'30%',textAlign:'left'}} htmlFor="name">姓名<br/>Name: </Form.Label>
            <Form.Control style={{width:'70%'}} type="text" disabled={true} placeholder={fulleNameWithTitle} {...register("fulleNameView")} />
          </Col>
          <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
            <Form.Label style={{width:'30%',textAlign:'left'}} htmlFor="name">公司名稱<br/>Company Name: </Form.Label>
            <Form.Control style={{width:'70%'}} type="text" disabled={true} placeholder={companyNameView} {...register("companyNameView")} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
            <Form.Label style={{width:'30%',textAlign:'left'}} htmlFor="posttitle">職銜<br/>Post Title: </Form.Label>
            <Form.Control style={{width:'70%'}} type="text" disabled={true} placeholder={postTitleView} {...register("postTitleView")} />
          </Col>
          <Col xs={12} md={6} style={{display:'flex',paddingBottom:'1rem',alignItems: 'center'}}>
            <Form.Label style={{width:'30%',textAlign:'left'}} htmlFor="phone">電話號碼<br/>Phone No. :<br/><span style={{color:'red'}}>*必須填寫 *Mandatory field</span></Form.Label>
            <Form.Control style={{width:'70%'}} type="tel" placeholder={""} {...register("phoneNumber",{ required: true, pattern:{value: /^(0|[1-9]\d*)(\.\d+)?$/}, minLength: 8, maxLength: 10 })} />
          </Col>
          {errors.phoneNumber && <span className="text-danger">請輸入正確的手機號碼 Please Enter A Valid Phone No.</span>}
        </Row>
        <Col xs={12} md={12} style={{fontFamily:'Noto Sans TC',textAlign:'left', display:'flex',paddingLeft:'1rem',paddingRight:'1rem',marginBottom:'1px',backgroundColor:'rgb(246, 245, 241)'}}>
          <div style={{display:'flex',flexDirection:'column',padding:'1rem',paddingBottom:'2rem', align: 'left'}}>
            <Form.Label style={{display:'flex',padding:'1rem 1rem 1rem 0',fontSize:'1.2rem',fontWeight:'bold',color:'rgb(47, 118, 183)'}}>出席意向 Intention of Attendance</Form.Label>
            <Form.Check type="radio" name="attend" label={<p>本人將會出席{eventNameView}。<br/>I will participate in {eventNameView}.</p>} value={AttendanceType.Attend} onClick={() => handleAttend(true)}/>
            <br/>
            <Form.Check type="radio" name="attend" label={<p>本人無暇出席{eventNameView}。<br/>I will not participate in {eventNameView}.</p>} value={AttendanceType.Absent} onClick={() => handleAttend(false)}/>
          </div>
        </Col>
        <Row style={{paddingBottom:'1.5rem',paddingTop:'1.5rem'}}>
        {
          !matches?
          <Col style={{textAlign:'center',paddingBottom:'1rem'}}>
          <Button type="submit" value="提交" style={{width:'200px',backgroundColor:'rgb(0, 97, 176)'}}>提交 Submit</Button>
        </Col>          
          :
          <Col style={{textAlign:'left',paddingBottom:'1rem',height: '3rem'}}>
          <Button type="submit" value="提交" style={{width:'180px',fontSize:'1.3rem',backgroundColor:'rgb(0, 97, 176)'}}>提交 Submit</Button>
        </Col>
        }
      </Row>
      </Form>
    </Container>
    </>
    }
    </>
  )
}
